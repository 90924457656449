  export class apiUrl {


    // region login
    public static get Login(): string { return "Authenticate"; }
    public static get CheckToken(): string { return "Authenticate/CheckToken"; }

    // end
    // region dashboard
    public static get DashboardDBasicData(): string { return "Reports/GetBasicData"; }
    public static get GetStaffBasicData(): string { return "Reports/GetStaffBasicData"; }


    public static get GetUserDetails(): string { return "Users/GetUserDetails"; }
    
    // end



    public static get GetDashboardWarnings(): string { return "CustomNotifications/GetDashboardWarnings"; }


    // region locations
    public static get LocationsGetAllForDropwDown(): string { return "Locations/GetdlLocations"; }  //options
    public static get LocationsGetAll(): string { return "Locations"; }   //options
    public static get LocationsGetById(): string { return "Locations"; }   //options
    public static get LocationsSave(): string { return "Locations"; }   //post
    public static get LocationsUpdate(): string { return "Locations"; }       //put
    public static get LocationsDisable(): string { return "Locations/DisableLocation"; } //put
    public static get LocationsDelete(): string { return "Locations"; }   //delete
    // end


    // region stores
    public static get StoreGetAllForDropwDown(): string { return "Store/GetdlStores"; }  //options
    public static get StoreGetAll(): string { return "Store/GetStores"; }   //options
    public static get StoreGetById(): string { return "Store"; }   //options
    public static get StoreSave(): string { return "Store"; }   //post
    public static get StoreUpdate(): string { return "Store"; }       //put
    public static get StoreDisable(): string { return "Store/DisableStore"; } //put
    public static get StoreDelete(): string { return "Store"; }   //delete
    // end


    // region stores
    public static get StorageGetAllForDropwDown(): string { return "Storage/GetdlStorages"; }  //options
    public static get StorageGetAll(): string { return "Storage/GetStorages"; }   //options
    public static get StorageGetById(): string { return "Storage"; }   //options
    public static get StorageSave(): string { return "Storage"; }   //post
    public static get StorageUpdate(): string { return "Storage"; }       //put
    public static get StorageDisable(): string { return "Storage/DisableStorage"; } //put
    public static get StorageDelete(): string { return "Storage"; }   //delete
    // end


        // region stores
        public static get ProductGetAllForDropwDown(): string { return "Product/GetdlProducts"; }  //options
        public static get ProductGetAll(): string { return "Product/GetProducts"; }   //options
        public static get ProductGetById(): string { return "Product"; }   //options
        public static get ProductSave(): string { return "Product"; }   //post
        public static get ProductUpdate(): string { return "Product"; }       //put
        public static get ProductDisable(): string { return "Product/DisableProduct"; } //put
        public static get ProductDelete(): string { return "Product"; }   //delete
        public static get ProductViewGetById(): string { return "Stock/GetProductStockDetails"; }   //options


    public static get ProductCategoryGetAllForDropwDown(): string { return "ProductCategory/GetdlProductCategorys"; }  //options
    public static get GetdlProductTypes(): string { return "Product/GetdlProductTypes"; }  //options
    public static get ProductCategoryGetAll(): string { return "ProductCategory/GetProductCategorys"; }   //options
    public static get ProductCategoryGetById(): string { return "ProductCategory"; }   //options
    public static get ProductCategorySave(): string { return "ProductCategory"; }   //post
    public static get ProductCategoryUpdate(): string { return "ProductCategory"; }       //put
    public static get ProductCategoryDisable(): string { return "ProductCategory/DisableProductCategory"; } //put
    public static get ProductCategoryDelete(): string { return "ProductCategory"; }   //delete

    public static get GetdlMeasuringUnits(): string { return "ProductCategory/GetdlMeasuringUnits"; }  //options

    // end
    public static get InsertMultipleStockDetail(): string { return "Stock/InsertMultipleStockDetail"; }   //post



    // region tools
    public static get ToolsGetAllForDropwDown(): string { return "Tools/GetdlTools"; }  //options
    public static get ToolsGetAll(): string { return "Tools"; }   //options
    public static get ToolsGetById(): string { return "Tools"; }   //options
    public static get ToolsSave(): string { return "Tools"; }   //post
    public static get ToolsUpdate(): string { return "Tools"; }       //put
    public static get ToolsDisable(): string { return "Tools/DisableTool"; } //put
    public static get ToolsDelete(): string { return "Tools"; }   //delete
    // end

    // region users
    public static get UsersGetAllForDropwDown(): string { return "Users/GetdlUsers"; }  //options
    public static get UsersGetAll(): string { return "Users"; }   //options
    public static get UsersGetById(): string { return "Users"; }   //options
    public static get UsersSave(): string { return "Users"; }   //post
    public static get UsersUpdate(): string { return "Users"; }       //put
    public static get UsersDisable(): string { return "Users/DisableUser"; } //put
    public static get UsersDelete(): string { return "Users"; }   //delete
    // end
    public static get GetdlDesignations(): string { return "Staffs/GetdlDesignations"; }  //options


     // region staffs
     public static get StaffsGetAllForDropwDown(): string { return "Staffs/GetdlStaffs"; }  //options
     public static get StaffsGetAll(): string { return "Staffs"; }   //options
     public static get StaffsGetById(): string { return "Staffs"; }   //options
     public static get StaffsSave(): string { return "Staffs"; }   //post
     public static get StaffsUpdate(): string { return "Staffs"; }       //put
     public static get StaffsDisable(): string { return "Staffs/DisableStaff"; } //put
     public static get StaffsDelete(): string { return "Staffs"; }   //delete
     // end
     public static get RolesGetAllForDropDown(): string { return "Users/GetdlRoles"; }  //options



      // region clients
    public static get ClientsGetAllForDropwDown(): string { return "Clients/GetdlClients"; }  //options
    public static get ClientsGetAll(): string { return "Clients"; }   //options
    public static get ClientsGetById(): string { return "Clients"; }   //options
    public static get ClientsSave(): string { return "Clients"; }   //post
    public static get ClientsUpdate(): string { return "Clients"; }       //put
    public static get ClientsDisable(): string { return "Clients/DisableClient"; } //put
    public static get ClientsDelete(): string { return "Clients"; }   //delete
    // end

    public static get JobsGetAllForDropwDownForSiteLog(): string { return "Jobs/GetdlJobsforsitelog"; }  //options


     // region jobs
     public static get JobsGetAllForDropwDown(): string { return "Jobs/GetdlJobs"; }  //options
     public static get DeleteAssignedStaffJobs(): string { return "JobStaffMappings/DeleteAssignedStaffJobs"; }  //options
     public static get JobsGetAll(): string { return "Jobs"; }   //options
     public static get JobsGetAllByFilter(): string { return "Jobs/GetJobs"; }   //post
     public static get GetStaffJobs(): string { return "Jobs/GetStaffJobs"; }   //post
     public static get GetUnAttendedJobs(): string { return "Jobs/GetUnAttendedJobs"; }   //post

     public static get GetStaffJobsDashboard(): string { return "Jobs/GetStaffJobsDashboard"; }   //post


     public static get JobsGetById(): string { return "Jobs"; }   //options
     public static get JobsSave(): string { return "Jobs"; }   //post
     public static get JobsUpdate(): string { return "Jobs"; }       //put
     public static get JobsDisable(): string { return "Jobs/DisableJob"; } //put
     public static get JobsDelete(): string { return "Jobs"; }   //delete



     public static get SaveStaffsForJob(): string { return "JobStaffMappings"; }   //post
     public static get GetStaffsForJob(): string { return "JobStaffMappings/GetJobStaffList/"; }   //options


     public static get SaveStaffsForTeam(): string { return "Teams/TeamStaffMapping"; }   //post
     public static get GetStaggForTeam(): string { return "Teams/GetTeamStaffs/"; }   //options

     // end



      // region vehicles
    public static get VehiclesGetAllForDropwDown(): string { return "Vehicles/GetdlVehicles"; }  //options
    public static get VehiclesGetAll(): string { return "Vehicles"; }   //options
    public static get VehiclesGetById(): string { return "Vehicles"; }   //options
    public static get VehiclesSave(): string { return "Vehicles"; }   //post
    public static get VehiclesUpdate(): string { return "Vehicles"; }       //put
    public static get VehiclesDisable(): string { return "Vehicles/DisableVehicle"; } //put
    public static get VehiclesDelete(): string { return "Vehicles"; }   //delete
    // end
      // region teams
    public static get TeamsGetAllForDropwDown(): string { return "Teams/GetdlTeams"; }  //options
    public static get TeamsGetAll(): string { return "Teams"; }   //options
    public static get TeamsGetById(): string { return "Teams"; }   //options
    public static get TeamsSave(): string { return "Teams"; }   //post
    public static get TeamsUpdate(): string { return "Teams"; }       //put
    public static get TeamsDisable(): string { return "Teams/DisableTeam"; } //put
    public static get TeamsDelete(): string { return "Teams"; }   //delete
    // end


    public static get StaffLeavesDelete(): string { return "StaffLeaves"; }
    
    

    public static get GetStaffLogsForApproval(): string { return "Staffs/GetStaffLogsForApproval"; }   //options
    public static get GetStaffLogsForUpdate(): string { return "Staffs/GetStaffLogsForUpdate"; }   //options

    public static get ApproveStaffLogs(): string { return "Staffs/ApproveStaffLogs"; }   //options
    public static get ApproveMultipleLeaves(): string { return "StaffLeaves/ApproveMultipleLeaves"; }   //options
    public static get UpdateStaffLog(): string { return "Staffs/UpdateStaffLog"; }   //options
    public static get GetStaffsLeaveCalender(): string { return "Reports/GetStaffsLeaveCalender"; }
   


    public static get UpdateToolLog(): string { return "Tools/PutToolLog"; }   //options
    public static get UpdateVehicleLog(): string { return "Vehicles/PutVehicleLog"; }   //options
    public static get UpdateStaffLeaveDate(): string { return "StaffLeaves/UpdateStaffLeaveDate"; }   //options


    public static get GetUnApporvedStaffLeaves(): string { return "StaffLeaves/GetUnApporvedStaffLeaves"; }   //options
    public static get ApproveLeave(): string { return "StaffLeaves/ApproveLeave/"; }   //options


    public static get LeaveEdit(): string { return "StaffLeaves"; }   //options
    public static get LeaveEditApproval(): string { return "StaffLeaves/UpdateStaffLeaveDate"; }   //options



    public static get GetStaffJobsReport(): string { return "Reports/GetStaffJobs"; }   //options
    public static get GetTeamJobsReport(): string { return "Reports/GetTeamJobs"; }   //options
    public static get GetVehicleJobsReport(): string { return "Reports/GetVehicleJobs"; }   //options
    public static get GetToolJobsReport(): string { return "Reports/GetToolJobs"; }   //options

    public static get GetJobsbyStatus(): string { return "Jobs/GetJobsbyStatus"; }   //options

    public static get UpdateJobStatus(): string { return "Jobs/UpdateJobStatus/"; }   //options




    public static get GetTop1ToolsAssignedJob(): string { return "Reports/GetTop1ToolsAssignedJob"; }   //options
    public static get GetTop1VehiclesAssignedJob(): string { return "Reports/GetTop1VehiclesAssignedJob"; }   //options
    public static get GetTop1StaffsAssignedJob(): string { return "Reports/GetTop1StaffsAssignedJob"; }   //options



  // region notications
  public static get CustomNotificationsGetAllForDropwDown(): string { return "CustomNotifications/GetdlCustomNotifications"; }  //options
  public static get CustomNotificationsGetAll(): string { return "CustomNotifications"; }   //options
  public static get CustomNotificationsGetById(): string { return "CustomNotifications"; }   //options
  public static get CustomNotificationsSave(): string { return "CustomNotifications"; }   //post
  public static get CustomNotificationsUpdate(): string { return "CustomNotifications"; }       //put
  public static get CustomNotificationsDisable(): string { return "CustomNotifications/DisableCustomNotification"; } //put
  public static get CustomNotificationsDelete(): string { return "CustomNotifications"; }   //delete
  // end
  public static get getNotificationMappingbyType(): string { return "CustomNotifications/getNotificationMappingbyType/"; }   //delete
  public static get SaveNotificationMapping(): string { return "CustomNotifications/SaveNotificationMapping"; }   //delete

  public static get UpdateAdminJobComment(): string { return "JobComments/UpdateAdminJobComment/"; }   //delete
  public static get PostStaffJobAssignments(): string { return "JobStaffMappings/PostStaffJobAssignments"; }   //delete
  
  
  public static get GetStaffJobRatings(): string { return "StaffJobRatings/GetStaffJobRatings"; }   //delete
  public static get PostStaffJobRatings(): string { return "StaffJobRatings/PostStaffJobRatings"; }   //delete
  public static get GetAssignedStaffJobs(): string { return "StaffJobRatings/GetAssignedStaffJobs"; }   //delete
  public static get DeleteStaffJobRatings(): string { return "StaffJobRatings/DeleteStaffJobRatings"; }   //delete
   


  public static get StaffStockLogGetAllForDropwDown(): string { return "StaffStockLog/GetdlStaffStockLogs"; }  //options
  public static get StaffStockLogGetAll(): string { return "StaffStockLog/GetStaffStockLog"; }   //options
  public static get StaffStockLogGetById(): string { return "StaffStockLog"; }   //options
  public static get StaffStockLogSave(): string { return "StaffStockLog"; }   //post
  public static get StaffStockLogUpdate(): string { return "StaffStockLog"; }       //put
  public static get StaffStockLogDisable(): string { return "StaffStockLog/DisableStaffStockLog"; } //put
  public static get StaffStockLogDelete(): string { return "StaffStockLog"; }   //delete


  public static get UpdateStaffStockLogClosed(): string { return "StaffStockLog/UpdateStaffStockLogClosed"; }   //delete
  public static get GetProductStocks(): string { return "Stock/GetProductStocks"; }   //options
  public static get GetProductStockDetails(): string { return "Stock/GetProductStockDetails"; }  //options



    // region stores
    public static get SupplierGetAllForDropwDown(): string { return "Supplier/GetdlSuppliers"; }  //options
    public static get SupplierGetAll(): string { return "Supplier/GetSuppliers"; }   //options
    public static get SupplierGetById(): string { return "Supplier"; }   //options
    public static get SupplierSave(): string { return "Supplier"; }   //post
    public static get SupplierUpdate(): string { return "Supplier"; }       //put
    public static get SupplierDisable(): string { return "Supplier/DisableSupplier"; } //put
    public static get SupplierDelete(): string { return "Supplier"; }   //delete
    // end
  }
