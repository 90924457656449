<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <span >Alerts</span>

    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(0)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
       
    <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Sl No</th>
              <th  scope="col">Name</th>
              <th  scope="col">Type</th>

              <th  scope="col">Expiry</th>


              <!-- <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of searchList| paginate: { itemsPerPage: itemsPerPage, currentPage: p  , totalItems: totalCount , id :'new1'} ;let i=index">
            
              <td>{{(i + 1)}}</td>
              <td >{{element.name}}</td>

              <td >{{element.type }}</td>
              <td >{{element.expiry}}</td>


             <!-- <td>
          <button class="btn btn-light" type="button" placement="top" ngbTooltip="view more"  (click)="onViewMore(element)">More</button>

             </td> -->


             


            </tr>
        
          </tbody>
        </table>

        <br>
        <pagination-controls id="new1" (pageChange)="p = $event; getData();"></pagination-controls>



      </div>
  
      <br>

  
  
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss(0)" class="btn btn-secondary" >Close</button>
  </div>