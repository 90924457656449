<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <span >Assign Staff  For Team :- {{team.teamName}}</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(0)">
      <span aria-hidden="true">&times;</span>
  
  
    </button>
  </div>
  <div class="modal-body">
  
  
  
    <form [formGroup]="masterForm" >
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label  for="staffId">{{'Staff' | translate}}
            <span class="validator-star">*</span>
          </label>
          <ng-select [items]="staffsList" placeholder="Select Staff"
          bindLabel="firstName"
          [multiple]="true"
            class="js-example-placeholder-multiple" formControlName="staffId"></ng-select>
          <span class="custom-validation-messages" *ngIf="submitted && f.staffId.errors" >
            <div *ngIf="f.staffId.errors.required" class="validation-message">Please provide a valid staff.</div>
          </span>
        </div>

        <div class="col-md-6 mb-3">
            <label  for="description">{{'Description' | translate}}
              <!-- <span class="validator-star">*</span> -->
            </label>
            <input class="form-control" id="description" type="text"  formControlName="description" >
            <span class="custom-validation-messages" *ngIf="submitted && f.description.errors" >
              <div *ngIf="f.description.errors.required" class="validation-message">Please provide a valid description.</div>
            </span>
          </div>


          <!-- <div class="col-md-6 mb-3">
            <label  for="isDriver">&nbsp;
              <span class="validator-star"></span>
            </label>
              <label class="d-block" for="chk-ani">
                <input class="checkbox_animated" formControlName="isDriver" id="chk-ani" type="checkbox" checked=""> Driver
              </label>
            </div>


            <div class="col-md-6 mb-3">
                <label  for="isTeamLeader">&nbsp;
                  <span class="validator-star"></span>
                </label>
                  <label class="d-block" for="chk-ani">
                    <input class="checkbox_animated" formControlName="isTeamLeader" id="chk-ani" type="checkbox" checked=""> Team Leader
                  </label>
                </div>
   -->
      </div>

      <div class="form-row">
        <button class="btn btn-primary mr-1" (click)="push()">
            <span>Push</span>
          </button>
      </div>
      <div class="form-row">
          &nbsp;
      </div>
     
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table">
              <thead class="thead-dark">
                <tr>
                    <th></th>
                  <th scope="col">Staff Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Driver</th>
                  <th scope="col">Leader</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedStaffs; let i = index;">
                    <th scope="row">



                        <span class="header-row-icons">
                            <i class="fa fa-trash-o row-delete" (click)="onDelete(i)"></i>
                        </span>
                       
                      </th>
                  <th scope="row">{{item.staffName }}</th>
                  <td>{{item?.description }}</td>
                  <td>

                    <!-- <label class="d-block" for="chk-ani">
                      <input class="checkbox_animated" [(ngModel)]="item.isDriver" (change)="item.vehicleId = 0" [ngModelOptions]="{standalone: true}"  id="chk-ani" type="checkbox" > 
                    </label> -->


                    <div class="form-check checkbox checkbox-primary mb-0">
                      <input class="form-check-input" id="checkbox-primary-17{{i}}" [(ngModel)]="item.isDriver" (change)="item.vehicleId = 0" [ngModelOptions]="{standalone: true}" type="checkbox" />
                      <label class="form-check-label" for="checkbox-primary-17{{i}}"></label>
                    </div>

                  </td>
                  <td>
                    <!-- <label class="d-block" for="chk-ani">
                      <input class="checkbox_animated" [(ngModel)]="item.isTeamLeader" [ngModelOptions]="{standalone: true}" id="chk-ani" type="checkbox" > 
                    </label> -->

                    <div class="form-check checkbox checkbox-primary mb-0">
                      <input class="form-check-input" id="checkbox-primary-1{{i}}" [(ngModel)]="item.isTeamLeader" [ngModelOptions]="{standalone: true}" type="checkbox" />
                      <label class="form-check-label" for="checkbox-primary-1{{i}}"></label>
                    </div>



                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
     
    </form>
  
  
  
  
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary mr-1" (click)="onSubmit()">
  
      <span>Submit</span>
    </button>
    <button (click)="modal.dismiss(0)" class="btn btn-secondary" >Close</button>
  
  </div>