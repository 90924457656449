import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'app-assigned-teams-by-job',
  templateUrl: './assigned-teams-by-job.component.html',
  styleUrls: ['./assigned-teams-by-job.component.css']
})
export class AssignedTeamsByJobComponent implements OnInit {
  assignedTeams = [];
  @Input() jobId : number = 0;
  constructor(private httpService : HttpService,
    public modal : NgbActiveModal,
    
    private toastr : ToastrService) { }

  ngOnInit(): void {

    this.getAssignedTeams();
  }


  getAssignedTeams(){
    this.httpService.getApi('JobStaffMappings/GetAssignedTeams/' + this.jobId).subscribe((resdata:any) =>{
      this.assignedTeams = resdata.result;
    })
  }



  onDelete(item : any){
    if(confirm('are you sure to remove this team')){
      let body = {
        jobId : this.jobId,
        teamId : item.teamId,
        assignedFor:item.assignedFor
      }
      this.httpService.postApi('JobStaffMappings/DeleteAssignedTeam' , body).subscribe((resdata: any) => {
        if(resdata.success == true){
      this.toastr.success('Deleted Successfully');
       this.getAssignedTeams();
        }
      })
    }
  }

}
