<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <span >{{status}}
        
        
      <span *ngIf="mode == 0"> Jobs </span>
      <span *ngIf="mode == 1"> Projects </span>
      <span *ngIf="mode == 2"> Tickets </span>

      </span>
  
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(0)">
      <span aria-hidden="true">&times;</span>
  
  
    </button>
  </div>
  <div class="modal-body">
  
    <div class="row">

      <div class="col-xl-9">


        </div>
    
        <div class="col-xl-3">
          <div class="job-filter">
            <div class="faq-form">
              <input class="form-control" type="text" placeholder="Search.."
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="search"
              (keyup)="getJobsData()"><i class="search-icon"
             
                data-feather="search"></i>
            </div>
          </div>
        </div>

   
      </div>
  
    <div class="table-responsive">
            

        <table class="table">
          <thead>
            <tr>
              <th scope="col">Sl No</th>

              <th scope="col">Code</th>
              <th scope="col">Open On</th>
              <th scope="col" *ngIf="status == 'Completed' || status == 'Closed'">Completed On</th>
              <th scope="col" *ngIf="status == 'Closed'">Closed On</th>


              <th scope="col">Client</th>
              <th scope="col">Location</th>
              <th scope="col">Status</th>
              <th scope="col">Ticket No</th>
              <th scope="col">Ticket Caller</th>
              <th></th>
              <th *ngIf="status == 'Pending' || status == 'Open'"></th>


            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of searchList | paginate: {  id: 'pagination2',itemsPerPage: itemsPerPage, currentPage: p , totalItems: totalCount  } ;let i=index">
            
              <td>{{((p-1) * itemsPerPage) + (i + 1)}}</td>
              <td>{{element.code}}</td>
              <td>{{element.openOn | date :'dd-MM-yyyy'}}</td>
              <td *ngIf="status == 'Completed' || status == 'Closed'">{{element.completedOn | date :'dd-MM-yyyy'}}</td>
              <td *ngIf="status == 'Closed'">{{element.closedOn | date :'dd-MM-yyyy'}}</td>


              <td>{{element.clientName}}</td>
              <td>{{element.locationName}}</td>
              <td>{{element.status}}</td>
              <td>{{element.ticketNo}}</td>
              <td>{{element.ticketCaller}}</td>
              <td *ngIf="status == 'Pending' || status == 'Open'">
                <button class="btn btn-pill btn-air-success btn-outline-success-2x" *ngIf="element.status != 'Closed'" (click)="assignTeam(element)" type="button" placement="top"
                ngbTooltip="Assign Team"> <i class="fa fa-plus" ></i></button>
            </td>

             <td>
          <button class="btn btn-light" type="button" placement="top" ngbTooltip="view more"  (click)="onViewMore(element)">More</button>

             </td>


             


            </tr>
        
          </tbody>
        </table>


      </div>
  
      <br>
      <pagination-controls  id="pagination2" (pageChange)="p = $event; getJobsData();"></pagination-controls>

  
  
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss(0)" class="btn btn-secondary" >Close</button>
  
  </div>