import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignJobTeamComponent } from 'src/app/views/masters/jobs/assign-job-team/assign-job-team.component';
import { IJobs } from 'src/app/views/masters/jobs/jobs.model';
import { JobsService } from 'src/app/views/masters/jobs/jobs.service';
import { apiUrl } from '../../constants/apiUrl-constants';

@Component({
  selector: 'app-job-popup',
  templateUrl: './job-popup.component.html',
  styleUrls: ['./job-popup.component.css']
})
export class JobPopupComponent implements OnInit {
  search : string ='';
  p : number =1;
  totalCount : number = 1;
  itemsPerPage : number = 10;

  @Input() mode : number = 0;
  public jobs : Array<IJobs> = [];
  public searchList : Array<IJobs> = [];


  @Input() status : string ;
  constructor(public modal : NgbActiveModal, 
    private modalService : NgbModal,
    private router : Router,
    private local : JobsService) { }

  ngOnInit(): void {
    this.getJobsData(); 
  }


  onViewMore(element : IJobs){
    this.modal.close();
    this.router.navigate(['/masters/jobs/job-detail'] ,  { queryParams: { jobId: element.id} } )
  }


  public assignTeam(job: IJobs) {
    const dialogRef = this.modalService.open(AssignJobTeamComponent, {
      centered: true,
      size: 'lg'
    });
    dialogRef.componentInstance.job = job;
    dialogRef.componentInstance.mode = 1;

    dialogRef.result.then(res => {
      if (res == 1) {
        this.getJobsData();
      }
    })
  }

  getJobsData(){
    let body = {
      "filterText": this.search,
      "status" : this.status,
      "pageIndex" : this.p,
      "pageSize" : this.itemsPerPage   ,
      "mode": this.mode
  
    }


    this.local.getAllJobsByFilter(apiUrl.GetStaffJobs , body).subscribe(resdata =>{

      if(resdata.success == true){
        this.jobs = resdata.result.items;
        this.searchList = resdata.result.items;
        if(resdata.result.items.length > 0){
          this.totalCount = resdata.result.totalCount;
          }
          else {
            this.totalCount = 1;
          }
      }
      })
    }
  

}
