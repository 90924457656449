import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'masters',
    loadChildren: () => import('../../views/masters/masters.module').then(m => m.MastersModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../views/dashboard/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'completed-jobs',
    loadChildren: () => import('../../views/completed-jobs/completed-jobs.module').then(m => m.CompletedJobsModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../views/reports/reports.module').then(m => m.ReportsModule)
  }, 
  {
    path: 'leave-approval',
    loadChildren: () => import('../../views/leave-approval/leave-approval.module').then(m => m.LeaveApprovalModule)
  },
  {
    path: 'staff-log-approval',
    loadChildren: () => import('../../views/staff-log-approval/staff-log-approval.module').then(m => m.StaffLogApprovalModule)
  },
  {
    path: 'update-staff-logs',
    loadChildren: () => import('../../views/update-staff-logs/update-staff-logs.module').then(m => m.UpdateStaffLogsModule)
  },
  {
    path: 'vehicle-logs-update',
    loadChildren: () => import('../../views/vehicle-logs-update/vehicle-logs-update.module').then(m => m.VehicleLogsUpdateModule)
  },
  {
    path: 'tool-logs-update',
    loadChildren: () => import('../../views/tool-logs-update/tool-logs-update.module').then(m => m.ToolLogsUpdateModule)
  },
  {
    path: 'staff-dashboard',
    loadChildren: () => import('../../views/dashboard/staff-dashboard/staff-dashboard.module').then(m => m.StaffDashboardModule)
  },
  {
    path: 'push-notifications',
    loadChildren: () => import('../../views/push-notifications/push-notifications.module').then(m => m.PushNotificationsModule)
  },
  {
    path: "calender",
    loadChildren: () => import("../../views/calender/calender.module").then((m) => m.CalenderModule),
  },
  {
    path: "job-staff-multiple-mapping",
    loadChildren: () => import("../../views/job-staff-multiple-mapping/job-staff-multiple-mapping.module").then((m) => m.JobStaffMultipleMappingModule),
  },
  {
    path: "assigned-job-staff-mappings",
    loadChildren: () => import("../../views/assigned-job-staff-mappings/assigned-job-staff-mappings.module").then((m) => m.AssignedJobStaffMappingsModule),
  },
  
];
