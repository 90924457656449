import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { apiUrl } from 'src/app/shared/constants/apiUrl-constants';
import { ITeams } from '../teams.model';
import { TeamsService } from '../teams.service';

@Component({
  selector: 'app-team-staff-mapping',
  templateUrl: './team-staff-mapping.component.html',
  styleUrls: ['./team-staff-mapping.component.css']
})
export class TeamStaffMappingComponent implements OnInit {

  @Input() team: ITeams;
  masterForm: FormGroup;
  public staffsList: Array<any> = [];
  public selectedStaffs: Array<any> = [];





  constructor(public modal: NgbActiveModal,
    private fb: FormBuilder,
    private toastr: ToastrService, private local: TeamsService) { }

  ngOnInit(): void {
    this.buildForm();
    this.getAllStaffsForJob();
    this.getAllStaffsForDropDown();


  }


  getAllStaffsForDropDown() {
    this.local.getAll(apiUrl.StaffsGetAllForDropwDown).subscribe(resdata => {
      this.staffsList = resdata.result;
    })
  }
  getAllStaffsForJob() {
    this.local.getAll(apiUrl.GetStaggForTeam + this.team.id).subscribe(resdata => {
      this.selectedStaffs = resdata.result;
    })
  }


  onDelete(index) {
    this.selectedStaffs.splice(index, 1);
  }



  public onSubmit() {

    if (this.selectedStaffs.filter(x =>
      x.isTeamLeader == true
    ).length > 1) {
      this.toastr.error('More than one team leader selected');
      return;
    }


    if (this.selectedStaffs.length == 0) {
      this.selectedStaffs.push({ teamId: this.team.id, staffId: 0 })
    }

    this.selectedStaffs.forEach(x => {
      x.submitBy = "1";
      x.submitOn = new Date
    })
    this.local.saveData(apiUrl.SaveStaffsForTeam, this.selectedStaffs).subscribe(resdata => {
      this.toastr.success('Assigned Successfully');
      this.modal.close(1);

    })









    //  console.log(this.selectedStaffs);

    //  let array = [];
    //  this.selectedStaffs.forEach(x =>{
    //   let element = this.staffsList.find(y =>  y.id == x )
    //   element.submitBy = "1";
    //    array.push(element);
    //  })

    //  this.local.saveData(apiUrl.SaveStaffsForJob, array).subscribe(resdata =>{
    //    
    //  })
  }

  public push() {


    this.submitted = true;
    if (this.masterForm.invalid) {

      return;
    }


    let staffList = [];
    staffList = this.f.staffId.value;

    // let pushArray =[];
    staffList.forEach(x => {
      let obj = {
        staffName: x.firstName,
        staffId: x.id,
        teamId: this.team.id,
        description: this.f.description.value,
        isTeamLeader: false,
        isDriver: false,
      }


      if (this.selectedStaffs.some(x => x.staffId == obj.staffId)) {
        // console.log('alread')
        this.toastr.warning( obj.staffName + ' already added');
        return;

      }
      else {
        this.selectedStaffs.push(obj);

      }



    })

    this.buildForm();
    this.submitted = false;
    //     this.submitted = true;
    //     if(this.masterForm.invalid){

    //       return;
    //     }
    //     let requestObj  = this.masterForm.value;
    // console.log(requestObj)

    //     if(this.selectedStaffs.some(x => x.staffId ==requestObj.staffId)){
    //       // console.log('alread')
    //     this.toastr.warning('this staff already added');
    //     return;

    //     }

    //     this.selectedStaffs.push(requestObj);

    //     requestObj.staffName = this.staffsList.find(x =>  x.id == requestObj.staffId).firstName;
    // this.selectedStaffs.forEach(x =>{
    //   x.staffName =  this.staffsList.find(y =>  y.staffId == x.id).staffName
    // })
    // this.buildForm();
    // this.submitted = false;
  }




  buildForm() {
    this.masterForm = this.fb.group({
      teamId: [this.team.id, Validators.required],
      staffId: [null, Validators.required],
      description: [""],
      // isTeamLeader  :[false, Validators.required],
      // isDriver  :[false, Validators.required],
      submitBy: ["1"],
      submitOn: [new Date]
    })
  }


  get f() { return this.masterForm.controls; }



  submitted: boolean = false;
}
