import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { apiUrl } from 'src/app/shared/constants/apiUrl-constants';
import { HttpService } from 'src/app/shared/services/http.service';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  userDetails : any ;
  constructor(private router : Router , private sharedData : SharedDataService) { }

  ngOnInit() {
    this.getUserDetails();
  }



  getUserDetails(){
    this.sharedData.userDetails.subscribe(resdata =>{
      this.userDetails = resdata ;
    })
  }

  onLogOut(){
    localStorage.clear();
    this.router.navigate(['/']);
  }

}
